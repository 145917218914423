.header {
  background: rgb(215,3,3);
  background: -moz-radial-gradient(circle, rgba(215,3,3,1) 35%, rgba(255,44,0,1) 86%, rgba(255,0,0,1) 100%);
  background: -webkit-radial-gradient(circle, rgba(215,3,3,1) 35%, rgba(255,44,0,1) 86%, rgba(255,0,0,1) 100%);
  background: radial-gradient(circle, rgba(215,3,3,1) 35%, rgba(255,44,0,1) 86%, rgba(255,0,0,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#d70303",endColorstr="#ff0000",GradientType=1);
}

@keyframes car {
  0% {
    background-position: -100% -100%;
    transform: rotate(0deg);
  }
  90% {
    background-position: 51% 30%;
    transform: rotate(2deg);
  }
  100% {
    background-position: center 30%;
  }
}

.logo {
  text-indent: -9999px;
  background: url('images/logo.svg') center center no-repeat transparent;
  height: 100px;
}

.header .car {
  position: absolute;
  animation: car 1s ease 0s 1 normal forwards;
  background: url('images/background.png') center center no-repeat transparent;
  transform: rotate(0deg);
  background-size: 50%;
  top: 0;
  left: 0;
  z-index: 0;

  img {
    visibility: hidden;
  }

  @media screen and (min-width: 960px ) {
    background-size: 760px;
  }

}


.road {
  background-color: black;
  height: 50px;
  position: relative;
}

.stripes {
  border-top: 6px dashed #fff;
  position: absolute;
  top: 50%;
  width: 100%;
  margin-top: -3px;
}

.boxes {
  margin-bottom: 100px;
  z-index: 1;

  @media screen and (max-width: 576px) {
    padding-top: 50vh;
  }
}
.box {
  margin-bottom: 30px;
}

.fancy-ticks {
  list-style-type: none;
  padding: 0;
}
.fancy-ticks li {
  padding: 24px  0 6px 48px;
  background: url('./images/tick.svg') left center no-repeat transparent;
  background-size: 32px;
}

.box .letter {
  font-family: 'Arial Black';
  font-size: 5em;
  line-height: 1em;
  border: 4px solid red;
  padding: 10px;
  width: 1.2em;
  margin: 0 auto;
  color: red;
}

.box .letter.pass {
  color: #4CAF50;
  border-color: #4CAF50;
}